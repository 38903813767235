.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  &__row {
    width: 926px;
    display: flex;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    background: #fcfcfc;
    border-radius: 25px;
    &Left {
      width: 50%;
      background: #2a73de;
      border-radius: 25px 50px 0px 25px;
      text-align: center;
      padding-top: 25px;
      padding-left: 25px;
      padding-bottom: 76px;
      padding-right: 25px;
      position: relative;
      &:after {
        background: #2a73de;
        content: '';
        display: inline-block;
        width: 65px;
        height: 48px;
        position: absolute;
        bottom: 0;
        right: -50px;
      }
    }
    &Right {
      width: 50%;
      position: relative;
      z-index: 2;
      background: #fcfcfc;
      border-radius: 0px 25px 25px 50px;
    }
  }
  &Form {
    padding: 30px;
    &__submit {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      button {
        background: #2a73de;
        border-radius: 5px;
        color: #fff;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        cursor: pointer;
        width: 169px;
        height: 41px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 2px 5px rgba(42, 115, 222, 0.25);
        border: none;
        transition: 0.3s all;
        &:hover {
          background: #2a73de;
          box-shadow: none;
        }
      }
    }
    &__label {
      display: inline-block;
      padding: 2px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: rgba(61, 61, 61, 0.5);
      background: #fcfcfc;
      position: absolute;
      left: 17px;
      transition: 0.3s all;
      top: 50%;
      transform: translateY(-50%);
    }
    &__group {
      margin-bottom: 20px;
      position: relative;
    }
    &__control {
      border: 1px solid #7a7a7a;
      border-radius: 5px;
      width: 100%;
      padding-left: 15px;
      height: 50px;
      background: transparent;
      &::placeholder {
        color: rgba(0, 0, 0, 0);
      }
      transition: 0.3s all;
      &:not(:placeholder-shown) {
        & + label {
          top: 0;
          font-size: 14px;
        }
      }
      &:focus {
        border: 1px solid #2a73de;
        filter: drop-shadow(0px 2px 5px rgba(42, 115, 222, 0.3));
        & + label {
          top: 0;
          color: #2a73de;
          font-size: 14px;
        }
      }
    }
    p {
      margin-bottom: 37px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #000000;
    }
    &__title {
      font-weight: 600;
      font-size: 30px;
      line-height: 37px;
      margin: 0;
      margin-bottom: 50px;
      padding: 0;
      margin-top: 56px;
      color: #2a73de;
      text-align: center;
      width: 100%;
    }
    &__checkbox {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #3d3d3d;
      label {
        position: relative;
        padding-left: 27px;
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          border: 0.5px solid #7a7a7a;
          border-radius: 3px;
          width: 17px;
          cursor: pointer;
          height: 17px;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        &:after {
          width: 4px;
          height: 10px;
          content: '';
          cursor: pointer;
          display: inline-block;
          position: absolute;
          left: 2px;
          top: 35%;
          border: solid white;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg) translateY(-50%);
        }
      }
      input {
        display: none;
        &:checked + label:before {
          background: #084c94;
        }
      }
    }
    &__registration {
      margin: 18px 0 0;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      text-align: center;
      color: #3d3d3d;

      a {
        display: block;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        color: #2a73de;
      }
    }
  }
  &__pic {
    max-width: 100%;
  }
  &__title {
    font-weight: 600;
    font-size: 24px;
    margin: 0;
    padding: 0;
    margin-bottom: 61px;
    line-height: 29px;
    color: #ffffff;
  }
  &__form {
    width: 400px;
    background: #fff;
    padding: 40px;
    border-radius: 20px;
  }
  &__input {
    width: 100%;
    border: none;
    border-bottom: 2px solid #000;
    padding-bottom: 5px;
    display: block;
    margin-bottom: 45px;
  }
  &__submit {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #2a73de;
    border-radius: 5px;
    margin-right: 12px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    background: #2a73de;
  }
}
