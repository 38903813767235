.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  /* display: none; */

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(61, 61, 61, 0.3);
  }
  &Content {
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: 20px;
    background: #fff;
    padding: 40px 40px 85px 40px;
    width: 100%;
    max-width: 1060px;
    max-height: 100%;
    overflow-y: auto;
    min-width: 350px;

    @media (max-width: 991px) {
      padding: 20px;
    }

    &__close {
      cursor: pointer;
      position: absolute;
      width: 32px;
      height: 32px;
      top: 15px;
      right: 15px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__bg {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;

      &Elem {
        position: absolute;

        &:nth-child(1) {
          left: 15px;
          top: 15px;
        }
        &:nth-child(2) {
          right: 0;
          bottom: 160px;
        }
      }
    }
  }
  &Body {
    display: flex;
    margin: 48px 0 0;

    @media (max-width: 991px) {
      flex-wrap: wrap;
    }

    &__title {
      color: #000;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      a {
        color: #2a73de;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      a:hover {
        color: #2662ba;
        border-bottom: 0.1em solid;
      }
    }
    &__btn {
    }
    &__field {
      display: flex;
      flex-direction: column;

      &:not(:first-child) {
        margin: 26px 0 0;
      }

      span {
        color: #3d3d3d;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 0 0 0 6px;
      }

      textarea,
      input {
        margin: 8px 0 0;
        border-radius: 12px;
        border: 1px solid #808080;
      }

      textarea {
        padding: 12px;
        height: 212px;
        resize: none;
      }
      input {
        height: 52px;
        padding: 0 12px;
      }
    }
    &__check {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin: 33px 0 0;

      input {
        display: none;

        &:checked + .fake {
          background-color: #084c94;
          border-color: #084c94;

          &::after {
            display: block;
          }
        }
      }
      .fake {
        position: relative;
        width: 25px;
        height: 25px;
        flex: 0 0 auto;
        border-radius: 4px;
        border: 1px solid #808080;

        &:hover {
          background-color: #ccc;
        }

        &::after {
          content: '';
          left: 8px;
          top: 4px;
          width: 4px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
          position: absolute;
          display: none;
        }
      }
      .text {
        margin: 0 0 0 10px;
        color: #3d3d3d;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    &__btn {
      cursor: pointer;
      height: 40px;
      padding: 0 25px;
      margin: 50px 0 0;
      border-radius: 5px;
      background: #2a73de;
      border: none;

      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
    &__col {
      &:nth-child(1) {
        flex: 0 0 100%;
      }
      &:nth-child(2) {
        flex: 1 1 auto;
      }
      &:not(:first-child) {
        margin: 0 0 0 30px;
      }

      @media (max-width: 991px) {
        flex: 1 1 auto !important;

        &:not(:first-child) {
          margin: 30px 0 0;
        }
      }
    }
    &__err {
      margin: 10px 0 0;
      color: rgb(255, 86, 86);
    }
    &__success {
      margin: 10px 0 0;
      color:  #3CB371;
    }
  }
}