.header {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  height: 42px;
  align-items: center;
  margin-bottom: 30px;
}

%col {
  text-align: center;
  &:nth-child(1) {
    width: 22.3%;
    text-align: left;
  }
  &:nth-child(2) {
    width: 15.6%;
  }
  &:nth-child(3) {
    width: 13.4%;
  }
  &:nth-child(4) {
    width: 15.7%;
  }
  &:nth-child(5) {
    width: 13.4%;
  }
}

.headerCol {
  @extend %col;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #2a73de;
  &:nth-child(1) {
    padding-left: 45px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  padding: 19px 15px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 15px;
}

.tableCol {
  b {
    display: none;
  }
  @extend %col;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #3d3d3d;
  display: flex;
  align-items: center;
  justify-content: center;
  &:nth-child(1) {
    justify-content: start;
  }
}

.pay {
  color: #159064;
}

.notpay {
  color: #ed1f24;
}

@media (max-width: 1200px) {
  .referalsWrapper {
    overflow: auto;
  }
  .referalsWrapper::-webkit-scrollbar {
    height: 7px;
    margin-top: 5px;
  }

  /* Track */
  .referalsWrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  .referalsWrapper::-webkit-scrollbar-thumb {
    background: #2a73de;
    border-radius: 10px;
  }

  /* Handle on hover */
  .referalsWrapper::-webkit-scrollbar-thumb:hover {
    background: #14458b;
  }
  .referalsTable {
    min-width: 1000px;
  }
}

@media (max-width: 767px) {
  .referalsTable {
    min-width: 10px;
  }
  .headerCol {
    font-size: 14px;
  }
  .row {
    flex-direction: column;
  }
  .tableCol {
    font-size: 16px;
    display: flex;
    margin: 0;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    justify-content: start;
    width: 100% !important;
    padding: 0;
    text-align: left;
    b {
      font-size: 16px;
      width: 130px;
      font-weight: 500;
      display: block;
    }
    span {
      font-size: 16px;
    }
  }
  .header {
    display: none;
  }
}
