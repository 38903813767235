/*Regular */
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.ttf'),
    url('../fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
/*Medium */
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Medium.ttf'),
    url('../fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
/*SemiBold */
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-SemiBold.ttf'),
    url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
/*Bold  */
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold.ttf'),
    url('../fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/*Bold  */
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-ExtraBold.ttf'),
    url('../fonts/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

* {
  box-sizing: border-box;
  outline: none !important;
  font-weight: 300;
  font-size: 20px;
  line-height: 1.2;
  font-family: 'Montserrat', sans-serif;
}

html {
  margin: 0;
  padding: 0;
  overflow-y: hidden;
}

body {
  margin: 0;
  padding: 0;
  color: #3d3d3d;
  font-weight: 400;
  font-size: 18px;
  overflow-x: hidden;
  line-height: 1.2;
  background: #f5f5f5;
}

li {
  list-style: none;
}

a:hover,
a:visited,
a {
  text-decoration: none;
  cursor: pointer;
}

img {
  width: 100%;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.container {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding: 0 15px;
}

.container_max {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding: 0 0px;
}

/************************************************************************/
nav {
  padding: 18px 45px 0;
  margin-bottom: 27px;
}

nav .post_repot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav .post_repot .logo {
}
nav .post_repot .logo img {
  max-width: 140px;
}

.MuiSlider-track {
  height: 6px !important;
  background: #2a73de !important;
}

.MuiSlider-thumb {
  width: 20px !important;
  height: 20px !important;
  background: #fff !important;
  border: 5px solid #2a73de !important;
  box-shadow: 0px 0px 4px #438be3;
}

nav .post_repot .right_wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

nav .post_repot .right_wrap .search {
}

nav .post_repot .right_wrap .search a {
}

nav .post_repot .right_wrap .search a img {
  width: 26px;
}

nav .post_repot .right_wrap .login {
  margin: 0 25px;
}

nav .post_repot .right_wrap .login a {
}

nav .post_repot .right_wrap .login a img {
  width: 30px;
}

nav .post_repot .right_wrap .user_control {
}

nav .post_repot .right_wrap .user_control .drop_btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

nav .post_repot .right_wrap .user_control .drop_btn .img_wrap {
  width: 35px;
  height: 35px;
  background-size: cover;
  border-radius: 5px;
  background-position: center;
}

nav .post_repot .right_wrap .user_control .drop_btn .btn_ret {
  margin-left: 5px;
}

nav .post_repot .right_wrap .user_control .drop_btn .btn_ret svg {
}

.main_content {
  overflow: auto;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.main_content .menu {
  background: #3384fa;
  border-radius: 0px 50px 0px 0px;
  width: 200px;
  height: 100%;
  padding-left: 10px;
  height: calc(100vh - 108px);
  position: fixed;
}

.main_content .menu .head_menu {
  display: none;
}

.main_content .menu .head_menu button {
  padding: 0;
  border: 0;
  background: transparent;
  margin-top: 35px;
}

.main_content .menu .head_menu button span {
  width: 25px;
  height: 2px;
  background: #fff;
  display: block;
  margin: 3px 0;
  border-radius: 2px;
}

.main_content .menu .content_menu {
  margin-top: 50px;
}

.main_content .menu .content_menu .list_items {
}

.main_content .menu .content_menu .list_items .item {
  margin-bottom: 14px;
}
.main_content .menu .content_menu .list_items .item a {
  border-radius: 21.5px 0px 0px 21.5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 9px 15px;
}

.main_content .menu .content_menu .list_items .item a.active {
  background: #ffffff;
}

.main_content .menu .content_menu .list_items .item a.active svg path {
  stroke: #084c94;
}

.main_content .menu .content_menu .list_items .item .img_item {
  display: flex;
  align-items: center;
}

.main_content .menu .content_menu .list_items .item .img_item img {
  width: 24px;
}

.main_content .menu .content_menu .list_items .item .name_item {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-left: 10px;
}

.main_content .menu .content_menu .list_items .item a.active .name_item {
  color: #084c94;
}

.main_content .menu .footer_menu {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
}

.main_content .menu .footer_menu .img_wraps {
  width: 100%;
  height: 150px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

body,
html {
  max-width: 100vw;
  overflow-x: hidden;
}

.i_cont {
  overflow-y: auto;
  width: calc(100% - 200px);
  padding: 0 45px;
  margin-left: 200px;
}

.i_cont .head_i {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 45px;
}

.i_cont .head_i .left_potr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.i_cont .head_i .left_potr .img_wrap {
}

.i_cont .head_i .left_potr .img_wrap img {
  width: 38px;
}

.i_cont .head_i .left_potr .title {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #3d3d3d;
  margin: 0 0 0 15px;
}

.i_cont .head_i .right_potr {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn_b {
  cursor: pointer;
}

.btn_b2 {
  cursor: pointer;
}

.i_cont .head_i .right_potr .btn_b {
  width: 240px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2a73de;
  border-radius: 5px;
  margin-right: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
  background: #2a73de;
}

.i_cont .head_i .right_potr .btn_b2 {
  width: 240px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2a73de;
  border-radius: 5px;
  margin-right: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
  background: #2a73de;
}

.i_cont .head_i .right_potr .btn_w {
  width: 240px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2a73de;
  border-radius: 5px;
  margin-right: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #2a73de;
  background: transparent;
}

.i_cont .search_panel {
  margin: 0 0 20px 0;
}

.i_cont .search_panel .lable {
  position: relative;
}

.i_cont .search_panel .lable .search_go {
  border: 0;
  position: absolute;
  left: 15px;
  top: 3px;
  background: transparent;
  cursor: pointer;
}

.search_go {
  cursor: default;
}

.i_cont .search_panel .lable .search_go svg {
}

.i_cont .search_panel .lable input {
  border: 0.5px solid #959595;
  border-radius: 5px;
  width: 100%;
  height: 32px;
  padding: 7px 0 7px 65px;
  font-size: 16px;
  background: #f5f5f5;
}

.i_cont .search_panel .lable input::placeholder {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #939393;
}

.i_cont .big_custom_polt {
  margin-bottom: 30px;
}

.i_cont .big_custom_polt .coups {
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  height: 42px;
  padding: 11px 20px;
}

.MuiSlider-rail {
  background: #d9d9d9;
  border-radius: 4px !important;
  height: 6px !important;
}

.MuiSlider-mark {
  display: none !important;
}

.MuiSlider-markLabel {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #b4b4b4 !important;
}

.i_cont .big_custom_polt .coups .os {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #3d3d3d;
  text-align: center;
}

.i_cont .big_custom_polt .coups .name {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #3d3d3d;
  text-align: center;
  padding: 0 50px 0 0;
}

.i_cont .big_custom_polt .coups .region {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #3d3d3d;
  text-align: center;
}

.i_cont .big_custom_polt .coups .ipder {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #3d3d3d;
  text-align: center;
}

.i_cont .big_custom_polt .coups .status {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #3d3d3d;
  text-align: center;
}

.i_cont .big_custom_polt .coups .time_left {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #3d3d3d;
  text-align: center;
}

.i_cont .big_custom_polt .coups .auto_renewal {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #3d3d3d;
  text-align: center;
}

.i_cont .big_custom_polt .coups .prodlen {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #3d3d3d;
}

.i_cont .big_custom_polt .coups .containerCH {
  width: 2%;
  margin: 0px 0 19px 0px;
  padding: 0;
  float: left;
}
.i_cont .big_custom_polt .coups .os {
  width: 7%;
  float: left;
}
.i_cont .big_custom_polt .coups .name {
  width: 13%;
  float: left;
}
.i_cont .big_custom_polt .coups .region {
  width: 6%;
  float: left;
}
.i_cont .big_custom_polt .coups .ipder {
  width: 22%;
  float: left;
}
.i_cont .big_custom_polt .coups .status {
  width: 6%;
  float: left;
}
.i_cont .big_custom_polt .coups .auto_renewal {
  /* width: calc(20% - 163px); */
  /* width: calc(22% - 82px); */
  width: 190px;
  float: left;
}
.i_cont .big_custom_polt .coups .time_left {
  /* width: calc(35% - 163px); */
  width: calc(35% - 272px);
  /* width: calc(17% - 82px); */
  float: left;
}
.i_cont .big_custom_polt .coups .prodlen {
  width: 163px;
  float: right;
  padding-left: 16px;
}

.panel_all {
}

.panel_all .accordion_fn {
}

.panel_all .accordion-item {
}

.empty {
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty p {
  margin: 0 0 0 0;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #a0a0a0;
}

.panel_all .accordion-item .wrap_inCH {
  height: 58px;
  background: #ffffff;
  border-radius: 10px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.panel_all .accordion-item .opened .wrap_inCH {
  border-radius: 10px 10px 0px 0px;
}

.panel_all .panelAccord.selected .wrap_inCH.active {
  background: #3384fa;
}

.panel_all .panelAccord.selected .renewalCh.active {
  background: transparent;
  border-color: #fff;
}
.panel_all .panelAccord.selected .renewalCh.active span:nth-child(1) {
  background: transparent;
  border-color: #fff;
  color: #fff;
}

.panel_all .panelAccord.selected .renewalCh.active span:nth-child(2) {
  background: #fff;
  color: #2a73de;
}

.panel_all .panelAccord.selected .wrap_inCH {
  background: #3384fa;
}

.panel_all .panelAccord.selected .renewalCh {
  background: transparent;
  border-color: #fff;
}
.panel_all .panelAccord.selected .renewalCh span:nth-child(2) {
  background: transparent;
  border-color: #fff;
  color: #fff;
}

.panel_all .panelAccord.selected .renewalCh span:nth-child(1) {
  background: #fff;
  color: #2a73de;
}

.panel_all .panelAccord.selected .wrap_inCH {
  background: #3384fa;
}

.panel_all .panelAccord.selected .divCHimg svg path {
  fill: #fff;
}

.panel_all .panelAccord.selected .ipCh span {
  color: #ffffff;
}

.panel_all .panelAccord.selected .statusCh span {
  color: #c5ffea;
}

.panel_all .panelAccord.selected .statusCh span.bad {
  color: #f6a2a2;
}

.panel_all .panelAccord.selected .dateCh span {
  color: #ffffff;
}

.panel_all .panelAccord.selected .btnCh .goMore {
  color: #3384fa;
  background: #ffffff;
}

.panel_all .panelAccord.selected .icon {
  background: #ffffff;
}

.panel_all .panelAccord.selected .icon svg line {
  stroke: #3384fa;
}

.panel_all .accordion-item .wrap_inCH .divCH {
  width: 60px;
}

.panel_all .accordion-item .divCHimg {
  text-align: center;
}

.panel_all .accordion-item .divCHimg svg {
  text-align: center;
}

.panel_all .accordion-item .flagCh, .vps_table .flagCh {
  text-align: center;
}
.panel_all .accordion-item .flagCh.eq-flag {
  padding: 0 0 0 24px;
}

.panel_all .accordion-item .divCHName {
  z-index: 1;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #3d3d3d;
  text-align: center;
}
.panel_all .accordion-item .divCHName span {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #3d3d3d;
}
.panel_all .accordion-item .divCHName.eq-server-name {
  justify-content: center;
}
.panel_all .accordion-item .divCHName.eq-server-name .eq-server-name-span {
  margin: 0 10px 0 0;
}

.panel_all .accordion-item .divCHName.eq-is-editing input {
  border: 1px solid #2a73de;
}
.panel_all .accordion-item .divCHName input {
  border: none;
  background: transparent;
  width: 100%;
  border: 1px solid transparent;
  padding: 8px 6px;
  border-radius: 5px;

  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #3d3d3d;
}

.panel_all .accordion-item .divCHName button {
  cursor: pointer;
  border: none;
  background: transparent;
  width: 18px;
  padding: 0;
  flex: 0 0 auto;
  margin: 0 0 0 3px;
}
.panel_all .accordion-item .divCHName button svg {
  width: 100%;
  height: 100%;
}
.panel_all .accordion-item .divCHName button svg {
  height: 22px;
  stroke: #2a73de;
}

.panel_all .accordion-item .flagCh img, .vps_table .flagCh img {
  text-align: center;
  max-width: 39px;
  box-shadow: 0 0 3px black;
  border-radius: 5px;
}

.panel_all .accordion-item .ipCh {
  text-align: center;
}

.panel_all .accordion-item .ipCh span {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #3d3d3d;
}

.panel_all .accordion-item .wrap_inCH .divCH {
  width: 2%;
  float: left;
}
.panel_all .accordion-item .wrap_inCH .divCHName {
  width: 12%;
  float: left;
}
.panel_all .accordion-item .divCHimg {
  width: 7%;
  float: left;
}
.panel_all .accordion-item .flagCh, .vps_table .flagCh {
  width: 6%;
  float: left;
}
.panel_all .accordion-item .ipCh {
  width: 21%;
  float: left;
}
.panel_all .accordion-item .statusCh {
  width: 10%;
  float: left;
}
.panel_all .accordion-item .dateCh {
  /* width: calc(35% - 163px); */
  width: calc(35% - 230px);
  float: left;
}

.btnRespam {
  float: right;
  width: 163px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.panel_all .accordion-item .btnCh {
}

.accordion .panelAccord .icon {
}

.accordion .panelAccord .icon {
  display: flex;
  height: 31px;
  background: #2a73de;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  width: 31px;
  margin-left: 11px;
  border: none;
  cursor: pointer;
}

.panel_all .accordion-item .statusCh {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.panel_all .accordion-item .statusCh span {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #159064;
}

.panel_all .accordion-item .statusCh span.bad {
  color: #ff1111;
}

.panel_all .accordion-item .dateCh {
  text-align: center;
}

.panel_all .accordion-item .dateCh span {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #3d3d3d;
}

.panel_all .accordion-item .btnCh .goMore {
  width: 103px;
  height: 32px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  background: #2a73de;
  border-radius: 5px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
}

.panel_all .accordion-item.opened .wrap_inCH {
  border-radius: 10px 10px 0px 0px;
}

.panel_all .accordion-item .contCH {
  display: flex;
  align-items: flex-start;
}

.panel_all .accordion-item .contCH .upravl .gopom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 132px;
  border-right: 0.5px solid #3d3d3d;
  padding: 0 15px 0 0;
}

.panel_all .accordion-item .contCH .upravl .gopom .stop {
  padding: 0;
  border: 0;
  line-height: 0;
  cursor: pointer;
}

.panel_all .accordion-item .contCH .upravl .gopom .stop svg {
}

.panel_all .accordion-item .contCH .upravl .gopom .play {
  padding: 0;
  border: 0;
  line-height: 0;
  cursor: pointer;
}

.panel_all .accordion-item .contCH .upravl .gopom .play svg {
}

.panel_all .accordion-item .contCH .upravl .gopom .reload {
  padding: 0;
  border: 0;
  line-height: 0;
  cursor: pointer;
}

.panel_all .accordion-item .contCH .gopom .reload svg {
}

.users_table .vps_table .vps_values .reload {
    padding: 0;
    border: 0;
    line-height: 0;
    cursor: pointer;
}

.users_table .vps_table .vps_values .reload svg {
}

.panel_all .accordion-item .contCH .users_data .line1 {
}

.panel_all .accordion-item .contCH .users_data .line1 .info_log {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3d3d3d;
}

.panel_all .accordion-item .contCH .users_data .line1 .info_log span {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #3d3d3d;
}

.panel_all .accordion-item .contCH .users_data .line2 {
  display: flex;
  align-items: center;
}

.panel_all .accordion-item .contCH .users_data .line2 .info_log {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3d3d3d;
}

.panel_all .accordion-item .contCH .users_data .line2 .info_log span {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #3d3d3d;
}

.panel_all .accordion-item .contCH .users_data .line2 .change_btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.panel_all .accordion-item .contCH .users_data .line2 .change_btn .wisible {
}

.panel_all
  .accordion-item
  .contCH
  .users_data
  .line2
  .change_btn
  .wisible
  .change_pass {
  width: 101px;
  height: 26px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  background: #2a73de;
  border-radius: 5px;
  border: 0;
  margin-left: 8px;
  cursor: pointer;
}

.panel_all .accordion-item .contCH .users_data .line2 .change_btn .unwisible {
  display: none;
}

.panel_all .accordion-item .contCH .users_data .line2 .change_btn .peckir {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-left: 10px;
  margin: 0 0 15px 0;
}

.panel_all
  .accordion-item
  .contCH
  .users_data
  .line2
  .change_btn
  .unwisible
  .form-group {
  display: flex;
  flex-direction: column;
}

.panel_all
  .accordion-item
  .contCH
  .users_data
  .line2
  .change_btn
  .unwisible
  .form-group.pad {
  margin: 0 6px 0 10px;
}

.panel_all
  .accordion-item
  .contCH
  .users_data
  .line2
  .change_btn
  .unwisible
  .form-group
  label {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #939393;
  margin-bottom: 1px;
}

.panel_all
  .accordion-item
  .contCH
  .users_data
  .line2
  .change_btn
  .unwisible
  .form-group
  input {
  background: #ffffff;
  border-radius: 5px;
  width: 140px;
  border: 0;
  height: 23px;
  padding: 1px 3px 1px 7px;
}

.panel_all
  .accordion-item
  .contCH
  .users_data
  .line2
  .change_btn
  .unwisible
  .form-group2 {
  line-height: 0;
  margin: 0 3px;
  position: relative;
  bottom: 1px;
}

.panel_all
  .accordion-item
  .contCH
  .users_data
  .line2
  .change_btn
  .unwisible
  .form-group2
  .normal {
  padding: 0;
  background: transparent;
  line-height: 0;
  border: 0;
  cursor: pointer;
}

.panel_all
  .accordion-item
  .contCH
  .users_data
  .line2
  .change_btn
  .unwisible
  .form-group2
  .normal
  svg {
}

.panel_all .accordion-item .contCH .users_data .line3 {
}

.panel_all .accordion-item .contCH .users_data .line3 .info_log {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3d3d3d;
}

.panel_all .accordion-item .contCH .users_data .line3 .info_log span {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #3d3d3d;
}
.panel_all .accordion-item .contCH .users_data .line4 .info_log {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3d3d3d;
}

.panel_all .accordion-item .contCH .users_data .line4 .info_log span {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #3d3d3d;
}

.panel_all .accordion-item .contCH .harakter .lips {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3d3d3d;
  margin-bottom: 12px;
}

.panel_all .accordion-item .contCH .harakter .infoHK {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #3d3d3d;
}

.panel_all .accordion-item .contCH .harakter .infoHK span {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3d3d3d;
}

.panel_all .accordion-item .contCH .upravl {
  width: 95px;
}
.panel_all .accordion-item .contCH .users_data {
  height: 132px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 9px 0;
  width: calc(100% - 553px);
  padding-left: 12px;
}
.panel_all .accordion-item .contCH .harakter {
  width: 250px;
}

.panel_all .accordion-item .contCH .harakter2 {
  width: 240px;
}

.panel_all .accordion-item .contCH .harakter2 .lips2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3d3d3d;
  margin-bottom: 12px;
}

.panel_all .accordion-item .contCH .harakter2 .text {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3d3d3d;
}

.pagination {
  padding: 20px 0;
}

.pagination ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pagination ul li {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #939393;
  margin: 0 2px;
}

/* @media (min-width: 761px) {
  .serversWrapper { */
/* max-height: calc(100vh - 180px); */
/* overflow: hidden; */
/* }
} */

.pagination ul li.active button {
  color: #3d3d3d;
}

.pagination ul li button {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #939393;
  border: none;
  background: transparent;
  cursor: pointer;
}

.pagination ul li a:hover {
  color: #3d3d3d;
  cursor: pointer;
}

/************************************************************************/
/************************************************************************/
/************************************************************************/

.accordion .accordion-item {
  margin-bottom: 15px;
}
.accordion .accordion-item button[aria-expanded='true'] {
}
.accordion .panelAccord {
  position: relative;
  width: 100%;
}

.accordion .panelAccord:hover,
.accordion .panelAccord:focus {
  cursor: pointer;
}
.accordion .panelAccord:hover::after,
.accordion .panelAccord:focus::after {
}

.accordion .panelAccord .icon.rotate svg {
  transform: rotate(180deg);
}

.accordion .accordion-content {
  opacity: 0;
  max-height: 0px;
  padding: 0px 20px;
  overflow: hidden;
  background: #dae8ff;
  transition: opacity 200ms linear, max-height 200ms linear,
    padding 200ms linear;
  will-change: opacity, max-height;
}

.accordion .accordion-content.opened {
  opacity: 1;
  max-height: 234px;
  padding: 60px 20px;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear,
    padding 200ms linear;
  will-change: opacity, max-height;
  background: #dae8ff;
  border-radius: 0px 0px 10px 10px;
}

/**********************************************/

/* The container */
.containerCH {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 19px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerCH input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #3d3d3d;
  border-radius: 2px;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.containerCH:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerCH input:checked ~ .checkmark {
  background-color: #084c94;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerCH input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerCH .checkmark:after {
  left: 6px;
  top: 1px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/******************************************************************/

@media (max-width: 1390px) {
  .panel_all
    .accordion-item
    .contCH
    .users_data
    .line2
    .change_btn
    .unwisible
    .form-group
    input {
    width: 120px;
  }
}

@media (max-width: 1350px) {
  .i_cont {
    margin-left: 180px;
  }
  .panel_all
    .accordion-item
    .contCH
    .users_data
    .line2
    .change_btn
    .unwisible
    .form-group
    input {
    width: 120px;
  }

  .i_cont {
    width: calc(100% - 180px);
    padding: 0 15px;
  }
  .main_content .menu {
    width: 180px;
  }
  .main_content .menu .content_menu .list_items .item .name_item {
    font-size: 14px;
    line-height: 16px;
  }

  .panel_all .accordion-item .contCH .users_data {
    width: calc(100% - 533px);
  }

  .panel_all .accordion-item .contCH .harakter {
    width: 240px;
  }

  .panel_all .accordion-item .contCH .harakter2 {
    width: 230px;
  }
}

@media (max-width: 1199px) {
  .over_hiden {
    overflow-x: scroll;
  }

  .inner_hiden {
    width: 1080px;
  }

  /* width */
  .over_hiden::-webkit-scrollbar {
    height: 7px;
    margin-top: 5px;
  }

  /* Track */
  .over_hiden::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  .over_hiden::-webkit-scrollbar-thumb {
    background: #2a73de;
    border-radius: 10px;
  }

  /* Handle on hover */
  .over_hiden::-webkit-scrollbar-thumb:hover {
    background: #14458b;
  }
}

.b2 {
  width: 170px;
  height: 33px;
  margin-right: 12px;
  font-size: 14px;
  line-height: 16px;
}

@media (max-width: 1600px) {
  .i_cont .head_i .right_potr .btn_b2 {
    width: 170px;
    height: 33px;
    margin-right: 12px;
    margin-top: 10px;
    font-size: 14px;
    line-height: 16px;
  }
}

@media (max-width: 1200px) {
  .i_cont .head_i .left_potr .img_wrap img {
    width: 30px;
  }
  .i_cont .head_i .left_potr .title {
    font-size: 20px;
    line-height: 25px;
    margin: 0 0 0 8px;
  }

  .i_cont .head_i .right_potr .btn_b {
    width: 170px;
    height: 33px;
    margin-right: 12px;
    font-size: 14px;
    line-height: 16px;
  }

  .i_cont .head_i .right_potr .btn_w {
    width: 205px;
    height: 33px;
    margin-right: 0;
    font-size: 14px;
    line-height: 16px;
  }

  nav {
    padding: 18px 15px 0;
    margin-bottom: 27px;
  }
}

.btn_w {
  cursor: pointer;
}

@media (max-width: 950px) {
  .i_cont {
    width: calc(100% - 50px);
    padding: 0 15px 0 15px;
    margin-left: 50px;
  }
  .MuiSlider-rail {
    height: 4px !important;
  }
  nav {
    padding: 7px 9px 0;
    margin-bottom: 16px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: 7px;
    background: #f5f5f5;
  }
  .App {
    padding-top: 58px;
    overflow-y: auto;
    height: 100vh;
  }
  nav .post_repot .logo img {
    max-width: 70px;
  }

  nav .post_repot .right_wrap .search a img {
    width: 21px;
  }

  nav .post_repot .right_wrap .login a img {
    width: 27px;
  }

  nav .post_repot .right_wrap .login {
    margin: 0 15px;
  }

  .i_cont .head_i {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 30px;
    flex-direction: column;
  }
  .i_cont .head_i .left_potr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .i_cont .head_i .right_potr {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
  }

  .i_cont .head_i .left_potr .img_wrap img {
    width: 23px;
  }

  .main_content .menu .footer_menu {
    display: none;
  }

  .main_content .menu .content_menu .list_items .item .name_item {
    display: none;
  }

  .main_content .menu .content_menu .list_items .item .img_item img {
    width: 20px;
  }
  .main_content .menu .content_menu {
    margin-top: 27px;
  }

  .main_content .menu {
    width: 50px;
    border-radius: 0px 20px 0px 0px;
    padding-left: 0px;
    display: flex;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 52px;
    bottom: 0;
    height: calc(100vh - 52px);
    z-index: 2;
  }

  .i_cont .head_i .right_potr .btn_b {
    width: 100%;
    height: 33px;
    font-size: 13px;
    line-height: 16px;
    margin: 0 0 10px 0;
  }
  .i_cont .head_i .right_potr .btn_w {
    width: 100%;
    height: 33px;
    margin-right: 0;
    font-size: 13px;
    line-height: 16px;
  }

  .i_cont .search_panel .lable input {
    padding: 7px 7px 7px 40px;
  }

  .i_cont .search_panel .lable .search_go {
    left: 8px;
  }
  .main_content .menu .content_menu .list_items .item a {
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    padding: 9px 15px;
    height: 44px;
    width: 44px;
    display: flex;
  }

  .pagination {
    padding: 20px 18px 20px 0;
  }
}

.shadow {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 23;
  background: #00000073;
  top: 0;
  left: 0;
  display: none;
  padding: 0px 10px;
}

.shadow.opened {
  display: flex;
}

.shadow .modal_center {
  max-width: 740px;
  background: #fff;
  border-radius: 20px;
  padding: 25px 32px;
  position: relative;
  width: 100%;
  max-height: 90vh;
  overflow: auto;
}

.shadow .modal_center .fon1 {
  position: absolute;
  left: 17px;
  top: 26px;
  width: 45px;
}
.shadow .modal_center .fon2 {
  position: absolute;
  right: 14px;
  bottom: 70px;
  width: 55px;
}

.shadow .modal_center .close {
  position: absolute;
  right: 18px;
  top: 18px;
  cursor: pointer;
}

.shadow .modal_center .close svg {
}

.container_modal {
}

.container_modal h3 {
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  margin: 0 0 0 0;
  text-align: center;
  color: #2a73de;
}

.container_modal .form_ger {
}

.container_modal .form_ger .wrap_zag {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 30px;
}
.container_modal .form_ger .wrap_zag .svg {
  border: 2px solid #2a73de;
  box-shadow: 0px 0px 4px rgb(42 115 222 / 30%);
  width: 34px;
  height: 34px;
  display: flex;
  border-radius: 22px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: relative;
  background: #fff;
}
.container_modal .form_ger .wrap_zag .svg span {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;

  color: #2a73de;
}

.container_modal .form_ger .wrap_zag .text {
}

.container_modal .form_ger .wrap_zag .text p {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  margin: 0 0 0 0;
}

.container_modal .form_ger .wrap_zag .text p span {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.container_modal .group_btn1 {
  padding-left: 45px;
}

.container_modal .group_btn1 .flex_btn {
  background: #e3e1e1;
  border-radius: 16px;
  width: 414px;
  height: 61px;
  display: flex;
}

.container_modal .group_btn1 .flex_btn .memory {
  background: transparent;
  border: 1px solid transparent;
  box-shadow: none;
  border-radius: 16px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.container_modal .group_btn1 .flex_btn .memory.active {
  background: #ffffff;
  border: 1px solid #2a73de;
  box-shadow: 1px 0px 5px rgb(0 0 0 / 25%);
}

.container_modal .group_btn1 .flex_btn .memory img {
  max-width: 180px;
  max-height: 40px;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  object-fit: contain;
}

.container_modal .group_btn1 .flex_btn .memory.active img {
  -webkit-filter: none; /* Safari 6.0 - 9.0 */
  filter: none;
}

.container_modal .group_btn {
  padding-left: 45px;
}

.container_modal .group_btn .group {
}

.group_btn .group {
  position: relative;
  margin-bottom: 15px;
}
.group_btn input {
  padding: 10px 11px 10px 11px;
  display: block;
  width: 100%;
  border-bottom: 1px solid #757575;
  border: 1px solid #7a7a7a;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #3d3d3d;
  height: 42px;
}
.group_btn input:focus {
  border: 1px solid #2a73de;
  box-shadow: 0px 2px 5px rgba(42, 115, 222, 0.3);
}
.group_btn input:valid {
  border: 1px solid #2a73de;
  box-shadow: 0px 2px 5px rgba(42, 115, 222, 0.3);
}

/* LABEL ======================================= */
.group_btn label {
  position: absolute;
  pointer-events: none;
  left: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  top: 13px;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
  color: rgba(61, 61, 61, 0.6);
}

/* active state */
.group_btn input:focus ~ label,
.group_btn input:valid ~ label {
  top: -9px;
  left: 16px;
  font-size: 14px;
  color: #2a73de;
  background: #fff;
  display: block;
  width: fit-content;
  padding: 0 3px;
}

.container_modal .form_ger .politic {
  margin: 5px 0 30px 0;
  padding-left: 45px;
}

.container_modal .form_ger .politic a {
  color: #2a73de;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: block;
  border-bottom: 1px solid #2a73de;
  width: fit-content;
}

.container_modal .form_ger .politic a:hover {
  color: #2a73de;
  border-bottom: 1px solid #2a73de;
}

.container_modal .form_ger .politic a:hover {
}

.container_modal .form_ger .btn_sub {
}

.container_modal .form_ger .btn_sub button {
  width: 162px;
  height: 45px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  border: 1px solid #2a73de;
  border-radius: 5px;
  color: #2a73de;
  display: flex;
  background: #fff;
  margin: 0 auto 15px;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}

.container_modal .form_ger .btn_sub button:hover {
  background: #2a73de;
  cursor: pointer;
  color: #fff;
  transition: all 0.5s ease;
}

@media (max-width: 1310px) {
  .shadow {
    align-items: flex-start;
    justify-content: center;
    z-index: 23;
    padding: 35px 10px 20px;
    overflow-y: scroll;
  }
}

@media (max-width: 760px) {
  .container_modal .form_ger .wrap_zag .text {
    width: calc(100% - 40px);
  }
  .container_modal .form_ger .wrap_zag .text p {
    font-size: 18px;
  }
  .container_modal .form_ger .wrap_zag .text span {
    font-size: 12px;
    line-height: 16px;
    /* display: block; */
    display: inline-flex;
  }
  .shadow .modal_center {
    border-radius: 20px;
    padding: 20px 15px;
    width: 100%;
  }
  .container_modal .group_btn {
    padding-left: 0;
  }
  .container_modal .group_btn1 {
    padding-left: 0;
  }
}

.users_fields {
  width: 100%;
  height: 100%;
  display: flex;
  float: left;
  margin-top: -21px;
}

.users_fields div {
  text-align: center;
}

.users_table {
  width: 100%;
  float: left;
  display: block;
  background: #ffffff;
  border-radius: 8px;
  flex-direction: column;
  scrollbar-width: none;
  overflow: auto;
}

.users_values {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

.users_values.even {
  background: #ffffff;
}

.users_values.odd {
  background: #f1f1f1;
}

.users_values div {
  text-align: center;
  padding: 8px;
  align-items: center;
  justify-content: center;
}
.vps_table {
  width: 100%;
  height: auto;
  float: left;
  display: block;
  background: #d3d3d3;
  flex-direction: column;
  scrollbar-width: none;
  overflow: auto;
}

.vps_values {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

.vps_values.header {
  background: #d1d1d1;
  border-bottom: 2px solid #989898;
}

.vps_values.header div {
  font-weight: 600;
}

.vps_values.even {
  background: #f7f7f7;
}

.vps_values.odd {
  background: #d1d1d1;
}

.vps_values div {
  display: flex;
  flex: 1 1 0;
  height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 6px;
}

.vps_values > div:not(:last-child) {
  border-right: 2px solid #989898;
}

.vps_button {
  width: 240px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2a73de;
  border-radius: 5px;
  margin-right: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
  background: #2a73de;
  cursor: pointer;
}

.vps_button2 {
  width: 240px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2a73de;
  border-radius: 5px;
  margin-right: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #2a73de;
  border: 2px solid #2a73de;
  background: WHITE;
  cursor: pointer;
}

.numeric-property {
  display: flex;
  flex-direction: row;
  gap: 5px;
  border: 1px solid #2a73de;
  border-radius: 8px;
  padding: 4px 12px 4px 12px;
  align-items: center;
}

.non-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pagination {
  display: flex;
  padding: 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background: white;
  border-radius: 10px;
  justify-content: center;
  overflow: hidden;
}

.pagination .arrow {
  display: flex;
  aspect-ratio: 1;
  padding: 10px;
  justify-content: center;
  cursor: pointer;
}

.pagination .arrow:hover,
.pagination .page:hover {
  background: rgba(0, 0, 0, 0.05);
}

.pagination .page {
  display: flex;
  padding: 10px;
  justify-content: center;
  aspect-ratio: 1;
  color: #aaaaaa;
  cursor: pointer;
}

.pagination .page.selected {
  color: #2a73de;
  font-weight: 500;
}

/*Telegram ChatId*/
.divChatId {
    z-index: 1;
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #3d3d3d;
    text-align: center;
}

.divChatId span {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #3d3d3d;
}

.divChatId.eq-telegram {
    justify-content: center;
}

.divChatId.eq-telegram .eq-telegram-span {
    margin: 0 10px 0 0;
}

.divChatId.eq-is-editing input {
    border: 1px solid #2a73de;
}

.divChatId input {
    border: none;
    background: transparent;
    width: 100%;
    border: 1px solid transparent;
    padding: 8px 6px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #3d3d3d;
}

.divChatId button {
    cursor: pointer;
    border: none;
    background: transparent;
    width: 18px;
    padding: 0;
    flex: 0 0 auto;
    margin: 0 0 0 3px;
}

.divChatId button svg {
    width: 100%;
    height: 100%;
}

.divChatId button svg {
    height: 22px;
    stroke: #2a73de;
}
/*Telegram ChatId End*/

/*User Edit Password*/
.divResetPassword {
    z-index: 1;
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #3d3d3d;
    text-align: center;
}

.divResetPassword.eq-password {
    justify-content: center;
}

.divResetPassword.eq-password .eq-password-span {
    margin: 0 10px 0 0;
}

.divResetPassword.eq-is-editing input {
    border: 1px solid #2a73de;
}

.divResetPassword input {
    border: none;
    background: transparent;
    border: 1px solid transparent;
    padding: 8px 6px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #3d3d3d;
}

.divResetPassword button {
    cursor: pointer;
    border: none;
    background: transparent;
    width: 16px;
    padding: 0;
    flex: 0 0 auto;
    margin: 0 0 0 3px;
}

.divResetPassword button svg {
    width: 100%;
    height: 100%;
}

.divResetPassword button svg {
    height: 22px;
    stroke: #2a73de;
}
/*User Edit Password End*/