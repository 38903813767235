.osImage {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-top: 6px;
}

.renewalCh {
  width: 140px;
  display: flex;
  border: 2px solid #2a73de;
  border-radius: 5px;
  height: 32px;
  margin: 0 0 0 10px;

  &.active {
    span {
      &:nth-child(1) {
        background: transparent;
        color: #2a73de;
      }
      &:nth-child(2) {
        background: #2a73de;
        color: #fff;
      }
    }
  }

  span {
    flex: 1 1 auto;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #2a73de;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:nth-child(1) {
      background: #2a73de;
      color: #fff;
    }
  }
}
