.left {
    width: 100%;
    max-width: 678px;

    .select_os_wrapper {
        display: flex;
        flex-direction: row;
        gap: 12px;

        @media all and (min-width: 768px) {
            flex-direction: row;
            align-items: center;
        }

        .label {
            font-weight: 600;
            font-size: 20px;
            line-height: 1.2;
            color: #3D3D3D;
            margin-top: 0;
            margin-bottom: 0;
            padding-right: 0px;
            white-space: nowrap;
            width: 240px;
            text-align: end;
        }

        .select_os {
            flex-direction: row;
            position: relative;
            width: 270px;
            height: 24px;

            @media all and (min-width: 768px) {
                margin: 0px;
            }

            > div {

                > div {

                    &::after {
                        content: "";
                        display: block;
                        width: 10px;
                        height: 10px;
                        transform: rotate(-45deg);
                        border-style: solid;
                        border-width: 0 0 1px 1px;
                        border-color: #2A73DE;
                        pointer-events: none;
                        position: absolute;
                        top: calc(50% - 5px);
                        right: 14px;
                        z-index: 1;
                        margin-top: -3px;
                        cursor: pointer;
                        transition: transform 0.2s;
                    }
                }

                > div[aria-expanded="true"] {
                    &::after {
                        top: calc(50%);
                        transform: rotate(-135deg) scaleX(-1);
                    }
                }
            }
        }
    }
}