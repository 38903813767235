.btn_autorenew {
  margin: 0 0 0 12px;

  &:nth-child(2) {
    margin: 0 !important;
  }

  @media (max-width: 950px) {
    margin: 10px 0 0 !important;
    height: 33px !important;

    br {
      display: none;
    }
  }
}
