.item {
}

.backdrop {
  background: rgba(61, 61, 61, 0.3);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
  display: none;
  &.active {
    display: block;
  }
}

.refPopup {
  width: 819px;
  max-height: 90vh;
  overflow: auto;
  position: fixed;
  left: -500%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: #ffffff;
  border-radius: 20px;
  z-index: 1000000;
  opacity: 0;
  padding: 10px 64px 60px 65px;
  transition: 0.3s opacity;
  &.active {
    left: 50%;
    opacity: 1;
  }
}

.close {
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 3;
  transition: 0.3s all;
}

.tarifSelect {
  display: flex;
  background: rgba(217, 217, 217, 0.6);
  border-radius: 10px;
  overflow: hidden;
  margin-top: 30px;
  &__item {
    width: 33.333%;
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: rgba(61, 61, 61, 0.7);
    height: 40px;
    cursor: pointer;
    border-radius: 0;
    &Active {
      background: #2a73de;
      color: #fff;
    }
  }
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin-bottom: 20px;
  margin-top: 30px;
}

.txt {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #000000;
  margin-bottom: 22.4px;
}

.subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #2a73de;
  margin-bottom: 9px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -25px;
  margin-left: -25px;
}

.item {
  width: 35%;
  margin-top: 31px;
  padding-left: 25px;
  padding-right: 25px;
  &__count {
    width: 53px;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #3d3d3d;
  }
  &__txt {
    margin-top: 20px;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    max-width: 317px;
  }
  &_sm {
    width: 30%;
  }
  &_lg {
    width: 60.666%;
  }
}

@media (max-width: 991px) {
  .refPopup {
    width: 90vw;
    padding: 10px 20px 30px 20px;
  }
  .title {
    font-size: 18px;
    line-height: 25px;
  }
  .txt {
    font-size: 14px;
  }
  .subtitle {
    font-size: 16px;
  }
  .item {
    width: 50% !important;
    &__txt {
      font-size: 12px;
      margin-top: 10px;
    }
  }
}

.twofa div {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.twofa h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

.twofa h4 {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #2a73de;
}

.twofa li {
  margin-top: 8px;
  position: relative;
  left: 20px;
  padding-right: 20px;
  list-style-position: initial;
  list-style-image: initial;
  list-style-type: decimal;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.twofa .bottom {
  margin-top: 24px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.twofa .bottom .right {
  width: 320px;
  word-break: break-all;
}

.twofa label {
  display: inline-block;
  padding: 2px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(61, 61, 61, 0.5);
  background: #fcfcfc;
  position: absolute;
  left: 17px;
  transition: 0.3s all;
  top: 50%;
  transform: translateY(-50%);
}

.twofa .group {
  margin-bottom: 20px;
  position: relative;

  .error {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    color: red;
    font-size: 12px;
  }
}

.twofa .control {
  border: 1.5px solid #2a73de;
  border-radius: 10px;
  width: 100%;
  padding-left: 15px;
  height: 50px;
  background: transparent;
  &::placeholder {
    color: rgba(0, 0, 0, 0);
  }
  transition: 0.3s all;
  &:not(:placeholder-shown) {
    & + label {
      top: 0;
      font-size: 14px;
    }
  }
  &:focus {
    border: 1px solid #2a73de;
    filter: drop-shadow(0px 2px 5px rgba(42, 115, 222, 0.3));
    & + label {
      top: 0;
      color: #2a73de;
      font-size: 14px;
    }
  }
}

.twofa .buttons {
  display: flex;
  justify-content: space-around;
  font-weight: 700;

  &.ableButtons > button:last-child {
    width: 152px;
  }
}

.twofa .button {
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  font-weight: 700;
}

.twofa .inputError {
  border-color: red;
}

.MuiButton {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 16px;
}

.depositAmount {
  margin: 16px 0 0;

  & > span {
    display: inline-flex;
    align-items: center;
    height: 42px;
    padding: 0 20px;
    border-radius: 5px;
    border: 1px solid #2a73de;
    background: #fff;
    box-shadow: 0px 2px 5px 0px rgba(42, 115, 222, 0.3);
  }
}

.wrapZag {
  &.notify {
    display: flex;
    flex-direction: column;
    align-items: center !important;

    .depositAmount {
      padding: 0;
      margin: 0 auto 28px;
    }
  }

  .depositAmount {
    margin: 0 auto 0 0;
    padding: 0 20px 0 0;
  }
}
