.refInfo {
    background: #FFFFFF;
    border-radius: 10px;
    margin-top: 29px;
    display: flex;
    justify-content: space-between;
    padding: 20px 13px;
    padding-bottom: 25px;
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, auto));
    grid-template-areas:
        "user ref" "twofa telegram";
}

.user {
  grid-area: user;
  width: 100%;
}

.ref {
  grid-area: ref;
  width: 100%;
}

.twofa {
  margin-top: 50px;
  grid-area: twofa;
}

.settings {
  padding-left: 47px;
  color: #159064;
  cursor: pointer;
}

.telegram {
    margin-top: 50px;
    grid-area: telegram;
    width: 100%;
}

.title {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3D3D3D;
  margin: 0;
  margin-bottom: 25px;
  img {
    max-width: 32px;
    margin-right: 15px;
  }
}

.left {
  .infoTitle {
    width: 77px;
    margin-right: 17px;
  }
}

.leftCol {
  @extend %col;

  .infoTitle {
    width: 77px;
    margin-right: 17px;
  }

  &_switchWrapper {
    display: flex;
    align-items: center;
    padding-left: 46px;

    .label {
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }

    .twofaSwitcher {
      cursor: pointer;
      width: 56px;
      height: 20px;
      border: 2px solid #2A73DE;
      border-radius: 999px;
      box-sizing: border-box;
      margin-left: 25px;
      position: relative;
      background: #FFF;
      transition: 0.3s;

      .dot {
        position: absolute;
        width: 10px;
        height: 10px;
        left: 6px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 999px;
        background: #2A73DE;
        transition: 0.3s;
      }

      &_on {
        background: #2A73DE;

        .dot {
          left: 38px;
          background: #FFF;
        }

      }

      &_disabled {
        border: 2px solid grey;

        .dot {
          background: grey;
        }
      }


    }

  }


}

.rightCol {
  @extend %col;
  .infoTitle {
    width: 147px;
    margin-right: 30px;
  }
}

.info {
    padding-left: 47px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    &Title {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #3D3D3D;
        margin: 0;
    }

    &Content {
        margin: 0;
        padding: 0;

        p {
            margin: 0;
            padding: 0;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #159064;

            strong {
                font-weight: 600;
            }
        }
    }

    &Text {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #3D3D3D;
        margin: 0;
        margin-right: 17px;

        a {
            color: #2a73de;
        }

        a:hover {
            color: #3384fa;
        }
    }
}

.infoPassword {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #3D3D3D;
  height: 16px;
  border: none;
  background: #ffffff;
  width: 90px;
  margin-right: 20px;
  &Wrapper {
    display: flex;
    align-items: center;
  }
}

.view {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
}


@media(max-width: 991px) {
  .ref {
    &Info {
      flex-wrap: wrap;
    }
  }
  %col {
    width: 100%;
  }
  .leftCol {
    margin-bottom: 30px;
  }
}

@media(max-width: 767px) {
  .title {
    font-size: 16px;
    img {
      margin-right: 10px;
    }
  }
  .info {
    padding-left: 0;
    &Password {
      font-size: 12px;
      line-height: 12px;
      height: 20px;
    }
    &Title {
      font-size: 14px;
      line-height: 18px;
    }
    &Content {
      p {
        font-size: 14px;
        line-height: 18px;
        strong {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
  .leftCol .infoTitle {
    width: 55px;
  }
}

@media(max-width: 567px) {
    .refInfo {
        grid-template-areas:
            "user" "ref" "twofa" "telegram";
    }
}