.balance {
  margin: 0 15px 15px;
  &__row {
    display: flex;
    align-items: center;
    &:nth-child(1) {
      img {
        width: auto;
        height: 20px;
        object-fit: contain;
        margin: 0 16px 0 0;
      }

      color: #fff;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  &__field {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 12px 0 0;
    height: 32px;
    position: relative;
    border-radius: 6px;
    border: 1.5px solid #fff;
  }

  &__input {
    padding: 0 25px 0 10px;
    width: 100%;
    background-color: transparent;
    border: none;
    color: #fff;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &__balance {
    display: flex;
    align-items: center;
    padding: 0 25px 0 10px;
    width: 100%;
    background-color: transparent;
    border: none;
    color: #fff;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    img {
      margin: 0 0 0 6px;
      width: auto;
      height: 14px;
      object-fit: contain;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    width: 14px;
    height: 14px;
    flex: 0 0 auto;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 8px;
    background-color: transparent;
    border: none;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
